@import "react-alice-carousel/lib/scss/alice-carousel.scss";

#root {
	transition: .3s;
	
}

.ReactModal__Body--open #root {
	-webkit-filter: blur(3px);
	filter: blur(3px);
}

.closebutton{
  position: absolute;
  right: 0 ;
  top: 0;
  z-index: 12001;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: inline-block;
  text-align: right;
}

.Modal {
  position: absolute;
  top: 5%;
  left: 5%;
  right: 5%;
  bottom: 5%;
  padding: 5%;
  
 
  
}



.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background:rgba(134, 134, 134, 0.692);
  z-index: 1200;
}


#farme_1{
  height: 440pt;
  
}
#farme_2{
  height: 380pt;
}

#farme_5{
  //height: 380pt;
  background: #EEEEEE;
}
.ff_02{
background: aliceblue;
}
#intro {
    width: 100%;
    position: relative;
   // background: #007bff url('/img/intro-bg.png') center bottom no-repeat;
    background-size: cover;
    padding: 80px 0 120px 0;
    color: $white
}
#intro .intro-img {
    width: 50%;
    float: right;
}

#intro .intro-info {
    width: 50%;
    float: left;
}

#intro .intro-info .btn-services {
    border: 2px solid #fff;
}
#intro .intro-info .btn-get-started {
    background: #007bff;
    border: 2px solid #007bff;
    color: #fff;
}

#intro .intro-info .btn-get-started, 
#intro .intro-info .btn-services {
    letter-spacing: 1px;
    display: inline-block;
    padding: 10px 32px;
    border-radius: 50px;
    transition: 0.5s;
    margin: 0 20px 20px 0;
    color: #fff;
}

#intro .intro-info .btn-services {
  border: 2px solid #fff;
}

#intro .intro-info .btn-services:hover {
  background: #007bff;
  border-color: #007bff;
  color: #fff;
}

/*--------------------------------------------------------------
# Responsive Media Queries
--------------------------------------------------------------*/

@media (min-width: 992px) {
  #testimonials .testimonial-item p {
    width: 80%;
  }
}

@media (max-width: 991px) {
  #header {
    height: 60px;
    padding: 10px 0;
  }

  #header .logo h1 {
    font-size: 28px;
    padding: 8px 0;
  }

  #intro {
    padding: 140px 0 60px 0;
  }

  #intro .intro-img {
    width: 80%;
    float: none;
    margin: 0 auto 25px auto;
  }

  #intro .intro-info {
    width: 80%;
    float: none;
    margin: auto;
    text-align: center;
  }

  #why-us .card {
    margin: 0;
  }



}

@media (max-width: 768px) {
  .back-to-top {
    bottom: 15px;
  }
}

@media (max-width: 767px) {
  #intro .intro-info {
    width: 100%;
  }

  #intro .intro-info h2 {
    font-size: 34px;
    margin-bottom: 20px;
  }

  .section-header p {
    width: 100%;
  }

  .Modal {
    top: 0%;
    left: 0%;
    right: 0%;
    bottom: 0%;
    padding: 0%;
  }
  .closebutton{
    right: 25px ;
    top: 10px;
    height: 25px;
    width: 25px;
    
  }


}

@media (max-width: 574px) {
  #intro {
    padding: 40px 0 20px 0;
  }
  #farme_1{
    height: auto;
  }
  #farme_2{
    height: auto;
  }
}