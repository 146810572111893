// variable
$blue:  #429dff !default;

$font-size-base:              0.9rem !default; // Assumes the browser default, typically `16px`
$font-size-lg:                $font-size-base * 1.25 !default;
$font-size-sm:                $font-size-base * .875 !default;

$font-weight-lighter:         lighter !default;
$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-bold:            700 !default;
$font-weight-bolder:          bolder !default;

$font-weight-base:            $font-weight-normal !default;
$line-height-base:            1.5 !default;

$h1-font-size:                $font-size-base * 2.5 !default;
$h2-font-size:                $font-size-base * 2 !default;
$h3-font-size:                $font-size-base * 1.75 !default;
$h4-font-size:                $font-size-base * 1.5 !default;
$h5-font-size:                $font-size-base * 1.25 !default;
$h6-font-size:                $font-size-base !default;

$display1-size:               3.4rem !default;
$display2-size:               3.0rem !default;
$display3-size:               2.3rem !default;
$display4-size:               1.5rem !default;
$display5-size:               1.4rem !default;


$bottom-0:                    0 !default;
$right-0:                     0 !default;
$left-0:                      0 !default;
$bottom-2:                    4rem !default;


@import '~bootstrap/scss/bootstrap.scss';
@import '../components/Home/scss/home.scss';
@import '../components/Doc/scss/doc.scss';
@import '~antd/dist/antd.css';
@import '../components/Maintenance/Maintenance.scss';
@import 'node_modules/react-modal-video/scss/modal-video.scss';

.botom-0{
    bottom: $bottom-0;
}

.botom-2{
    bottom: $bottom-2;
}

.right-0{
    right: $right-0
}
.left-0{
    left: $left-0
}

footer{
    background: aliceblue;
}

footer ul a{
    font-size: 0.85em;
}


.bd-callout {
    padding: 1.25rem;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    border: 1px solid #eee;
    border-left-width: .25rem;
    border-radius: .25rem;
}


.bd-callout-warning{
    border-left-color: #f0ad4e;
}

.show{
    display: inline-block;
}
.hide{
    display: none;
}

// menu style
.mm{
    text-transform: uppercase;
    line-height: 0.8em;
    white-space: nowrap;
}
.Ymaps{
    width: 100%;
    height: 350px;
}
.mm_des{
    color:silver;
    white-space: nowrap;
}
.active_mm,
.active_mm .mm_des{
color:#93DA1C !important;
}
.ChangeLanguage{
    padding: 2px;
}
.ChangeLanguage .selector{
    margin-right: 10px;
    float: left;

}
.ChangeLanguage span{
    font-size: 0.8em;
    margin-left: 2px;
    color: #9b9b9b;
    text-transform: uppercase;
    top: -6px;
    position: relative
}


@media (max-width: 1024px) {
       .left-0 {
        left:-100px
    }
}