
.search-loading {
	position: absolute;
	top: 0;
	margin: auto 0;
}

/*Show and hide*/
.show {
	display: inline-block;
}

.hide {
	display: none;
}