html, body, #root {
	height: 100%;
}


.container {
  display: table;
  width: 100%;
  height: 100%; /* For at least Firefox */
  min-height: 100%;
}

.inner-container {
	display: table-cell;
	vertical-align: middle;
}